import { ReactNode } from 'react'
import { Box, Grid } from '@mui/material'
import { ImgPicture, ImgPictureProps } from './imgPicture'
import { TypographyProps } from '@mui/material/Typography'
import { SxProps } from '@mui/system'
import { BlockText, BlockTitle } from './TypographyText'
import { Block } from './container'

export interface TextImgProps {
  ImgProps: ImgPictureProps
  textChildren?: ReactNode
  title?: TypographyProps
  description?: TypographyProps
  hasDescription?: boolean
  // ture 时 左图右文
  reverse?: boolean
  textBoxSx?: SxProps
}

/**
 * 图文布局(pc: 左右, mobile: 上文下图)
 */
export function TextImg({
  ImgProps,
  title,
  description,
  hasDescription,
  textChildren,
  reverse,
  textBoxSx,
}: TextImgProps) {
  const textBoxSxDefault: SxProps = {
    pl: {
      xs: 2,
      lg: reverse ? '38px' : '0px',
      xl: reverse ? '81px' : '0px',
    },
    pr: {
      xs: 2,
      lg: !reverse ? '38px' : '0px',
      xl: !reverse ? '81px' : '0px',
    },
  }

  return (
    <Block>
      <Grid
        container
        alignItems="center"
        columnSpacing="24px"
        sx={{
          flexDirection: {
            lg: reverse ? 'row-reverse' : 'row',
          },
        }}
      >
        <Grid item xs={12} lg={6}>
          <Box
            sx={{
              mb: {
                xs: '30px',
                lg: 0,
              },
              textAlign: {
                xs: 'center',
                lg: 'left',
              },
              ...textBoxSxDefault,
              ...textBoxSx,
            }}
          >
            {title && (
              <BlockTitle
                {...title}
                sx={{
                  mb: {
                    xs: description || hasDescription ? '16px' : '0',
                    lg: description || hasDescription ? '25px' : '0',
                  },
                  ...title.sx,
                }}
              />
            )}
            {description && (
              <BlockText {...description} sx={{ ...description.sx }} />
            )}
            {textChildren}
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ImgPicture {...ImgProps} />
        </Grid>
      </Grid>
    </Block>
  )
}
