import { Box } from '@mui/material'
import { shopifyUrl } from 'src/utils/routes'
// import required modules
import { FreeMode } from 'swiper'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import CollectionFeatureCard from './CollectionFeatureCard'
import TitleTypography from './TitleTypography'

const collections = [
  {
    title: 'Air Purifiers',
    href: shopifyUrl + '/collections/dreo-air-purifiers',
    img: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/Air_Purifiers.jpg?v=1700706387',
  },
  {
    title: 'Tower Fans',
    href: shopifyUrl + '/collections/dreo-tower-fans',
    img: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/Tower_Fans.jpg?v=1700706386',
  },
  {
    title: 'Smart Appliances',
    href: shopifyUrl + '/collections/dreo-smart-appliances',
    img: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/Smart_Appliances.jpg?v=1700706386',
  },
  {
    title: 'Accessories',
    href:
      shopifyUrl +
      '/collections/all?filter.p.m.custom.class_name_short=Accessories',
    img: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/Accessories.jpg?v=1700706386',
  },
]

const CollectionFeature = () => {
  return (
    <Box>
      <TitleTypography
        sx={{
          color: {
            xs: '#7a7a7a',
            lg: '#000',
          },
        }}
      >
        Featured Collections
      </TitleTypography>
      <Swiper
        slidesPerView={2}
        spaceBetween={10}
        freeMode={true}
        breakpoints={{
          768: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
        }}
        modules={[FreeMode]}
        className="collection-feature-swiper"
        style={{ overflow: 'inherit' }}
      >
        {collections.map((collection) => (
          <SwiperSlide key={collection.title}>
            <CollectionFeatureCard {...collection} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}

export default CollectionFeature
