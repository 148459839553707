import { SwiperArrowLeft, SwiperArrowRight } from '@icons/index'
import { Box, IconButton, IconButtonProps } from '@mui/material'
import React, { useRef, useState } from 'react'
import {
  Autoplay,
  Controller,
  EffectFade,
  Mousewheel,
  Navigation,
  Pagination,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { PaginationOptions } from 'swiper/types/modules/pagination'
import Banner from './Banner'

const ArrowButton = ({
  left,
  disabled,
  ...props
}: IconButtonProps & { left?: boolean; disabled: boolean }) => {
  return (
    <IconButton
      disabled={disabled}
      sx={{
        ...(disabled && {
          opacity: 0.2,
        }),
        '& svg': {
          fontSize: 24,
        },
      }}
      {...props}
    >
      {left ? (
        <SwiperArrowLeft sx={{ fill: 'none' }} />
      ) : (
        <SwiperArrowRight sx={{ fill: 'none' }} />
      )}
    </IconButton>
  )
}

const banners = [
  // {
  //   url: 'https://us.dreo.com/pages/2024-valentine-day-sale?utm_source=dreo_offical_website&utm_medium=banner&utm_campaign=2024-valentine-day-sale',
  //   alt: 'smart home appliances',
  //   imgs: {
  //     xs: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/Background-1.jpg?v=1707189273',
  //     md: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/Background_4a82c353-fe0b-4ac6-a991-0bd503c8d4db.jpg?v=1707189273',
  //     lg: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/Backgournd.jpg?v=1707189276',
  //   },
  //   children: (
  //     <Box
  //       position="absolute"
  //       top={{ xs: '0', lg: '50%' }}
  //       left={{ xs: '0', lg: '10%', xl: '15%' }}
  //       bottom={{ xs: '0', lg: 'auto' }}
  //       right={{ xs: '0', lg: 'auto' }}
  //       pt={{ xs: '20%', md: '26%', lg: 0 }}
  //       display={{ xs: 'flex', lg: 'block' }}
  //       flexDirection="column"
  //       sx={{
  //         transform: { lg: 'translateY(-50%)' },
  //       }}
  //     >
  //       <Typography
  //         component="h2"
  //         color="#fff"
  //         fontSize={{
  //           xs: 38,
  //           md: 50,
  //           lg: 52,
  //           xl: 56,
  //         }}
  //         lineHeight={1.25}
  //         fontWeight="800"
  //         textAlign={{ xs: 'center', lg: 'left' }}
  //         mb={{ xs: 1, lg: 1.2 }}
  //       >
  //         Valentine's Day Sale
  //       </Typography>
  //       <Box
  //         sx={{ flex: 1 }}
  //         display={{ xs: 'flex' }}
  //         flexDirection="column"
  //         justifyContent="space-between"
  //         pb={{ xs: '8%', md: '12%', lg: 0 }}
  //       >
  //         <Typography
  //           color="#fff"
  //           fontSize={{ xs: 20, md: 26, lg: 30 }}
  //           textAlign={{ xs: 'center', lg: 'left' }}
  //         >
  //           Up to{' '}
  //           <Typography
  //             color="#fff"
  //             component="span"
  //             fontSize={{ xs: 32, md: 40 }}
  //             fontWeight={800}
  //           >
  //             30%
  //           </Typography>{' '}
  //           off
  //         </Typography>
  //         <Typography
  //           color="#fff"
  //           fontSize={{ xs: 16, md: 18, lg: 25 }}
  //           textAlign={{ xs: 'center', lg: 'left' }}
  //           display={{ xs: 'none', lg: 'block' }}
  //           mb={{ lg: 10 }}
  //         >
  //           Feb. 05th - Feb. 14th
  //         </Typography>
  //         <div>
  //           <Box
  //             display={{ xs: 'flex', lg: 'block' }}
  //             justifyContent="center"
  //             mb={{ xs: 1, lg: 0 }}
  //           >
  //             <Button
  //               sx={{
  //                 bgcolor: '#fff',
  //                 color: '#A4404C!important',
  //                 fontWeight: 700,
  //                 px: { xs: 6, lg: 8 },
  //                 py: { xs: 1, lg: 1.5 },
  //                 '&:hover': {
  //                   bgcolor: '#e6dfdf',
  //                 },
  //               }}
  //             >
  //               Learn More
  //             </Button>
  //           </Box>
  //           <Typography
  //             color="#fff"
  //             fontSize={{ xs: 16, md: 18, lg: 28 }}
  //             textAlign={{ xs: 'center', lg: 'left' }}
  //             display={{ xs: 'block', lg: 'none' }}
  //           >
  //             Feb. 05th - Feb. 14th
  //           </Typography>
  //         </div>
  //       </Box>
  //     </Box>
  //   ),
  // },
  // {
  //   url:
  //     shopifyUrl +
  //     '/pages/dreo-christmas-deals?utm_source=official&utm_medium=website&utm_campaign=christmas_deals_banner',
  //   imgs: {
  //     xs: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/Christmas780x1040-pre.png?v=1702272833',
  //     md: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/Christmas1486x1328-pre.png?v=1702272834',
  //     lg: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/Christmas3840x1040-2.png?v=1702345882',
  //   },
  //   imgBlurDataURLs: {
  //     xs: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAKCAIAAAAGpYjXAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA5UlEQVQImWPIZWDIZGBIY2BIYWBIBpPpDAy5DAwMOQwM881s1nr5bvQLWOnmsTMqdrmDayZEoopBeLKq/mJbh8W2jjN0TOsYREE6EhkYUhkYshgYKhl4WxjEcsHcVIhEKQPr8hCr/0+Ofjm5YLGfcaOUQDYDA4MXA0Ozo/Z0c6NNHbnXrq1bH+XnKMDgwMDAEMbAsLOz8//Pb9625ikJMRPLCqUZGJwZGBi2Kapfjwr5P7VlW7zfEj+3O41ld/LiLzrbMeQzMBSArZkkrjhFUqmWgbeYgaGIgQnkFwjKY2DMY2CEcwEXskMxOTNB4gAAAABJRU5ErkJggg==',
  //     md: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAJCAIAAACExCpEAAAACXBIWXMAAAsTAAALEwEAmpwYAAABFklEQVQYlR3LO0tCAQBA4TNo0MMbaGpREiQU5u61GnLpIWpFktFghlRydblhaYFgPkPMBnOpCCQhIQypX9BStDRa5NIvKGgOKrjbgY9DBMIQpWefvj2EGMIB+l00YYigmAR5nfl6ztlY8jZ9q3XXwrFpTFKIddhWagsKQ8Mlo3lHGTZhAwiBDCmr7q2a/H65b9ULTXk+ijo1IIQASaMquSxBeMzIz3fp1/fLK58jYeoN2vu9gAMSizO3fv+hKAacYjYnP12cBqATJoE1qOWyf78/tfOyoGZKtFUyiXGDQd/V4QYeLNbWiucrH/8sRiseR9XnbqdiH8VkO7jcmp0mjipJdx5teXCkMSHeiLazUcsJxiO0aYR/jx5Qumf+ClQAAAAASUVORK5CYII=',
  //     lg: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAADCAIAAAAlXwkiAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAZElEQVQImWNwZGDwY2BIAqNQBobpqionowNmMYj1M/BnMTAwBDIwNDBIbrB2aGQQTmJgeLlu9v8PF/2FuDoYBLoYeBnCGRjyGBjmMogckFf7X1nw/+29vc2V/9cu/N9R/tbXHQAf1R3+zPLlKAAAAABJRU5ErkJggg==',
  //   },
  // },
  // {
  //   url: 'https://us.dreo.com/pages/2024-new-year-deals?utm_source=dreo_offical_website&utm_medium=banner&utm_campaign=2024-new-year-deals',
  //   alt: 'smart home appliances',
  //   imgs: {
  //     xs: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/mobile_aed8d48f-644e-4b8d-b8c4-7eaaf9553252.png?v=1704182614',
  //     md: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/pad_75e63aae-270d-4c1a-bb1a-9ec15dee70da.png?v=1704182614',
  //     lg: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/PC_9db69e6e-4ff4-4586-9d41-80ae580d73a0.png?v=1704182614',
  //   },
  // },
  {
    url: 'https://us.dreo.com/collections/deals?utm_source=dreo_official_website&utm_medium=banner&utm_campaign=sales_offers',
    alt: 'Sales & Offers',
    imgs: {
      xs: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/mobile_edb2da47-9623-4f0c-b8c0-fd769ab9ef4f.jpg?v=1714110107',
      md: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/pad_04f347cb-9064-4cd2-8449-9622fd792a1d.png?v=1714110108',
      lg: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/banner_b0cb7bc1-4ef7-489f-b57e-b8915058b676.png?v=1714110110',
    },
  },
  {
    url: 'https://us.dreo.com/pages/purifier-tower-fan-mc710s?utm_source=dreo_official_website&utm_medium=banner&utm_campaign=mc710s_sell',
    alt: 'purifier tower fan mc710s',
    imgs: {
      xs: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/MC710S-mobile.png?v=1712651162',
      md: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/MC710S-pad.png?v=1712651165',
      lg: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/MC710S-pc.png?v=1712651268',
    },
  },
  {
    url: '/chefmaker-combi-fryer',
    alt: 'smart home appliances',
    imgs: {
      xs: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/780_1040.jpg?v=1701672070',
      md: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/1486_1328.jpg?v=1701672072',
      lg: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/20240206-173309.jpg?v=1707212015',
    },
  },
  // {
  //   url: '/2024ces',
  //   imgs: {
  //     xs: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/mobile.png?v=1703575816',
  //     md: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/pad.png?v=1703575819',
  //     lg: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/PC_1.png?v=1703678737',
  //   },
  // },
]

/**
 * xs: 390x520
 * md: 743x664
 * lg: height 430
 * xl: height 520
 */
export default function HomeSlideshow() {
  const prevRef = useRef<HTMLButtonElement>(null)
  const nextRef = useRef<HTMLButtonElement>(null)

  const [activeIndex, setActiveIndex] = useState(0)

  const pagination: PaginationOptions = {
    clickable: true,
    renderBullet: (index: any, className: any) => {
      if (index >= banners.length) return ''
      return (
        '<span style="color:#fff;" class="dreo-swiper-pagination-bullet ' +
        className +
        '">' +
        (index + 1) +
        '</span>'
      )
    },
  }
  return (
    <Box
      sx={{
        '--swiper-pagination-bullet-width': '32px',
        '--swiper-pagination-bullet-height': '32px',
        '--swiper-pagination-bullet-opacity': '1',
        '--swiper-pagination-bullet-inactive-opacity': '0.4',
        '--swiper-pagination-color': 'transparent',
        '--swiper-pagination-bullet-inactive-color': 'transparent',
        '.dreo-swiper-pagination-bullet': {
          lineHeight: '28px',
          color: '#e7e7e7!important',
          textAlign: 'center',
          overflow: 'hidden',
          border: '2px solid #e7e7e7',
          fontWeight: 600,
          transition: 'all 0.3s ease',
        },
        '.swiper-pagination-bullet-active': {
          border: '2px solid #fff',
          color: '#fff!important',
        },
        '.swiper-pagination': {
          textAlign: 'right',
          pr: {
            xs: 3,
            lg: 4,
          },
        },
      }}
    >
      <Swiper
        // grabCursor={true}
        effect={'fade'}
        // creativeEffect={{
        //   prev: {
        //     shadow: true,
        //     origin: 'left center',
        //     translate: ['-5%', 0, -200],
        //     rotate: [0, 100, 0],
        //   },
        //   next: {
        //     origin: 'right center',
        //     translate: ['5%', 0, -200],
        //     rotate: [0, -100, 0],
        //   },
        //   prev: {
        //     shadow: true,
        //     translate: ['-20%', 0, -1],
        //   },
        //   next: {
        //     translate: ['100%', 0, 0],
        //   },
        // }}
        className="home-slideshow-swiper"
        pagination={pagination}
        navigation={{
          prevEl: '.arrow-left',
          nextEl: '.arrow-right',
        }}
        // spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        modules={[
          Pagination,
          Navigation,
          Controller,
          Mousewheel,
          EffectFade,
          Autoplay,
        ]}
        loop={true}
        autoplay={{ delay: 6000 }}
        onRealIndexChange={(swiper) => setActiveIndex(swiper.realIndex)}
      >
        {banners.map((item) => (
          <SwiperSlide key={item.url}>
            <Banner {...item} />
          </SwiperSlide>
        ))}
      </Swiper>

      <Box
        position="absolute"
        top="50%"
        left="0"
        width="100%"
        display={{ xs: 'none', lg: 'flex' }}
        justifyContent="space-between"
        sx={{ transform: 'translateY(-50%)' }}
        px={5}
        zIndex={10}
      >
        <ArrowButton
          className="arrow-left"
          ref={prevRef}
          left
          disabled={activeIndex === 0}
        />
        <ArrowButton
          className="arrow-right"
          ref={nextRef}
          disabled={activeIndex === banners.length - 1}
        />
      </Box>
    </Box>
  )
}
