import { SvgIconProps } from '@mui/material'
import { OrderStatus } from 'types/order'

import Preparing from '@icons/Preparing'
import OrderCanceled from '@icons/OrderCanceled'
// import OrderCompleted from '@icons/OrderCompleted'
import OrderFulfilled from '@icons/OrderFulfilled'
import PartiallyFulfilled from '@icons/PartiallyFulfilled'
// import PartiallyRefunded from '@icons/PartiallyRefunded'
// import Refunded from '@icons/Refunded'
import Unpaid from '@icons/Unpaid'

// export type Status =
//   | 'preparing'
//   | 'outOfStock'
//   | 'shippingUpdate'
//   | 'inTransit'
//   | 'outForDelivery'
//   | 'availableForPickup'
//   | 'failedAttempt'
//   | 'exception'
//   | 'delivered'

const componentTypes: { [K in OrderStatus]: any } = {
  packagePreparing: Preparing,
  orderFulfilled: OrderFulfilled,
  partiallyFulfilled: PartiallyFulfilled,
  orderCanceled: OrderCanceled,
  unpaid: Unpaid,
}

export interface OrderStatusProps extends SvgIconProps {
  status: OrderStatus
}
const OrderStatusIcon = ({ status, ...svgProps }: OrderStatusProps) => {
  const IconComponent = componentTypes[status]
  return <IconComponent {...svgProps} />
}

export default OrderStatusIcon
