/*
 * @Author: Walker Qin
 * @Description:
 * @Date: 2022-11-03 19:28:41
 * @LastEditTime: 2023-05-19 19:07:36
 */
// import C1FirstScreen from '@/components/landingPage/chefmaker-crowdfunding/FirstScreen'
import { NextSeo } from 'next-seo'
import Head from 'next/head'
import type { ReactElement } from 'react'
import Home from 'src/components/home'
// import C1HomeBanner from 'src/components/home/C1HomeBanner'
// import UsersSay from 'src/components/home/UsersSay'
import MetaKeywords from 'src/components/MetaKeywords'
import Layout from 'src/layouts'
import { homePage } from 'src/seoConfig'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

// const UsersSay = dynamic(() => import('../src/components/home/UsersSay'))

const HomePage = () => {
  return (
    <>
      <Head>
        <meta
          name="p:domain_verify"
          content="ed99887d3fae03f120f1c732c86dc750"
        />
      </Head>
      <NextSeo {...homePage} />

      <h1 className="visually-hidden">dreo tower fan</h1>

      <Home />

      <MetaKeywords keywords={homePage?.keywords} />

      {/* <C1HomeBanner /> */}
      {/* <HomeBanner /> */}
      {/* banner */}
      {/* <HomeBannerItem
        title="Tower Fan"
        href={`https://${process.env.NEXT_PUBLIC_SHOPIFY_PRIMARY_DOMAIN}/collections/dreo-tower-fans`}
        imgs={{
          xs: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/tower-fans-banner-xs.png?v=1692002986',
          md: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/tower-fans-banner-md.png?v=1692002986',
          lg: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/tower-fans-banner-lg.png?v=1692002986',
          xl: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/tower-fans-banner-xl.png?v=1692002986',
        }}
        description={
          <>
            The Mightier.
            <Typography
              component="span"
              display={{ xs: 'block', md: 'none' }}
            />
            The Quieter.
          </>
        }
      />
      <HomeBannerItem
        title="Air Circulator Fan"
        href={`https://${process.env.NEXT_PUBLIC_SHOPIFY_PRIMARY_DOMAIN}/collections/dreo-air-circulator-fans`}
        align="left"
        imgs={{
          xs: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/air-circulator-fans-banner-xs.png?v=1692002974',
          md: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/air-circulator-fans-banner-md.png?v=1692002974',
          lg: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/air-circulator-fans-banner-lg.png?v=1692002974',
          xl: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/air-circulator-fans-banner-xl.png?v=1692002974',
        }}
        description={
          <>
            Surround Air.
            <br />
            Surreal Comfort
          </>
        }
      /> */}

      {/* <C1FirstScreen learnMore /> */}

      {/* SectionBlock */}
      {/* <SectionBlock /> */}

      {/* <Recognised /> */}
      {/* <UsersSay /> */}
      {/* <Subscription /> */}
    </>
  )
}

export default HomePage

HomePage.getLayout = (component: ReactElement) => {
  return <Layout>{component}</Layout>
}

HomePage.sitePage = {
  pageType: 'homepage',
}
