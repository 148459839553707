import { shopifyUrl } from '@/utils/routes'
import { Box, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import Image from 'next/image'
import CollectionCard, {
  CollectionCardProps as CollectionCardItem,
} from './CollectionCard'

// const TitleType1 = styled(Typography)(({ theme }) => ({
//   color: theme.palette.common.white,
//   fontSize: 24,
//   fontWeight: 800,
//   letterSpacing: '-0.48px',
//   lineHeight: 1.2,
//   textShadow: '0px 4px 14px rgba(152, 120, 96, 0.40)',
//   leadingTrim: 'both',
//   textEdge: 'cap',
//   textAlign: 'center',
//   [theme.breakpoints.up('md')]: {
//     fontSize: 32,
//   },
//   [theme.breakpoints.up('lg')]: {
//     textAlign: 'left',
//     fontSize: 36,
//   },
//   [theme.breakpoints.up('xl')]: {
//     fontSize: 40,
//   },
// }))

const TitleType2 = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: 20,
  fontWeight: 800,
  letterSpacing: '-0.48px',
  lineHeight: 1.2,
  leadingTrim: 'both',
  textEdge: 'cap',
  marginBottom: 10,
  [theme.breakpoints.up('md')]: {
    fontSize: 17,
    marginBottom: 12,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 26,
    color: theme.palette.common.black,
    marginBottom: 6,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 32,
  },
}))

const SubHeating = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  lineHeight: 1.2,
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    fontSize: 16,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 16,
    color: '#1D1D1D',
  },
})) as typeof Typography

const TextWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '35%',
  left: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.24)',
  backdropFilter: 'blur(40px)',
  padding: '0px 20px ',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    padding: 20,
    height: 'auto',
  },
  [theme.breakpoints.up('lg')]: {
    height: 'auto',
    bottom: 'auto',
    top: 28,
    background: 'transparent',
    backdropFilter: 'none',
    padding: 28,
  },
}))

const list: CollectionCardItem[] = [
  {
    href: shopifyUrl + '/collections/dreo-tower-fans',
    content: (
      <Box
        sx={{
          position: 'absolute',
          py: { xs: 2.5, lg: 3.5 },
          px: { xs: 2.5, lg: 6.5 },
          left: 0,
          width: '100%',
        }}
      >
        <TitleType2>Tower Fan</TitleType2>
        <SubHeating component={'div'}>
          The Mightier.
          <Box display={{ xs: 'none', lg: 'block' }} />
          The Quieter.
        </SubHeating>
      </Box>
    ),
    bg: (
      <Box className="product-image-wrapper">
        <Box display={{ xs: 'block', md: 'none' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/xs-1.png?v=1713248536"
            alt=""
            width={342}
            height={240}
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
        <Box display={{ xs: 'none', md: 'block', lg: 'none' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/md-1.png?v=1713248533"
            alt=""
            width={647}
            height={320}
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
        <Box display={{ xs: 'none', lg: 'block', xl: 'none' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/LG-1.png?v=1713248533"
            alt=""
            width={469}
            height={400}
            style={{ objectFit: 'cover' }}
          />
        </Box>
        <Box display={{ xs: 'none', xl: 'block' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/xl-1.png?v=1713248538"
            alt=""
            width={592}
            height={400}
            style={{ objectFit: 'cover' }}
          />
        </Box>
      </Box>
    ),
  },
  {
    href: shopifyUrl + '/collections/new-arrivals',
    content: (
      <>
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          className="product-image-wrapper"
        >
          <Box display={{ xs: 'block', md: 'none' }}>
            <Image
              src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/xs-2.png?v=1713248538"
              alt=""
              width={342}
              height={240}
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
          <Box display={{ xs: 'none', md: 'block', lg: 'none' }}>
            <Image
              src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/md-2.png?v=1713248533"
              alt=""
              width={319}
              height={320}
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
          <Box display={{ xs: 'none', lg: 'block', xl: 'none' }}>
            <Image
              src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/LG-2.png?v=1713248533"
              alt=""
              width={469}
              height={190}
            />
          </Box>
          <Box display={{ xs: 'none', xl: 'block' }}>
            <Image
              src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/xl-2.png?v=1713248538"
              alt=""
              width={592}
              height={189}
            />
          </Box>
        </Box>
        <TextWrapper>
          <TitleType2>New Arrivals</TitleType2>
          <SubHeating component={'div'}>
            Latest Innovation for
            <Box display={{ xs: 'none', lg: 'block' }} />
            This Summer
          </SubHeating>
        </TextWrapper>
      </>
    ),
    bg: (
      <>
        <Box display={{ xs: 'block', md: 'none' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/home-collection-2-xs-bg-2x.png?v=1700821156"
            alt=""
            width={342}
            height={240}
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
        <Box display={{ xs: 'none', md: 'block', lg: 'none' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/collection-bg-md-2.png?v=1700843853"
            alt=""
            width={319}
            height={320}
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
        <Box display={{ xs: 'none', lg: 'block', xl: 'none' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/home-collection-2-lg-bg.png?v=1700636450"
            alt=""
            width={469}
            height={190}
            style={{ objectFit: 'cover' }}
          />
        </Box>
        <Box display={{ xs: 'none', xl: 'block' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/home-collection-2-xl-bg.png?v=1700636450"
            alt=""
            width={592}
            height={189}
            style={{ objectFit: 'cover' }}
          />
        </Box>
      </>
    ),
  },
  {
    href: shopifyUrl + '/collections/humidifiers',
    content: (
      <>
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          className="product-image-wrapper"
        >
          <Box display={{ xs: 'block', md: 'none' }}>
            <Image
              src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/home-collection-3-xs-2x.png?v=1700821313"
              alt=""
              width={342}
              height={240}
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
          <Box display={{ xs: 'none', md: 'block', lg: 'none' }}>
            <Image
              src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/c-3-product-md.png?v=1700822187"
              alt=""
              width={319}
              height={320}
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
          <Box display={{ xs: 'none', lg: 'block', xl: 'none' }}>
            <Image
              src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/home-collection-3-lg.png?v=1700636450"
              alt=""
              width={469}
              height={190}
            />
          </Box>
          <Box display={{ xs: 'none', xl: 'block' }}>
            <Image
              src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/home-collection-3-xl.png?v=1700636450"
              alt=""
              width={592}
              height={189}
            />
          </Box>
        </Box>
        <TextWrapper>
          <TitleType2>Humidifiers</TitleType2>
          <SubHeating component={'div'}>
            Swift Hydration.&nbsp;
            <Box display={{ xs: 'none', lg: 'block' }} />
            Natural Comfort.
          </SubHeating>
        </TextWrapper>
      </>
    ),
    bg: (
      <>
        <Box display={{ xs: 'block', md: 'none' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/home-collection-3-xs-bg-2x.png?v=1700821156"
            alt=""
            width={342}
            height={240}
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
        <Box display={{ xs: 'none', md: 'block', lg: 'none' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/collection-bg-md-3.png?v=1700843853"
            alt=""
            width={319}
            height={320}
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
        <Box display={{ xs: 'none', lg: 'block', xl: 'none' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/home-collection-3-lg-bg.png?v=1700636450"
            alt=""
            width={469}
            height={190}
            style={{ objectFit: 'cover' }}
          />
        </Box>
        <Box display={{ xs: 'none', xl: 'block' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/home-collection-3-xl-bg.png?v=1700636450"
            alt=""
            width={592}
            height={189}
            style={{ objectFit: 'cover' }}
          />
        </Box>
      </>
    ),
  },
  {
    href: shopifyUrl + '/collections/dreo-air-circulator-fans',
    content: (
      <TextWrapper
        sx={{
          top: { xs: 'auto', lg: '50%!important' },
          bottom: { xs: 0, lg: 'auto' },
          transform: { xs: 'translateY(0)', lg: 'translateY(-50%)' },
          px: {
            xs: 3.5,
            lg: 6.5,
          },
        }}
      >
        <TitleType2>PolyFan</TitleType2>
        <SubHeating>
          The Most Smart and Powerful{' '}
          <Box display={{ xs: 'none', lg: 'block' }} />
          All-in-One Fan
        </SubHeating>
      </TextWrapper>
    ),
    bg: (
      <Box className="product-image-wrapper">
        <Box display={{ xs: 'block', md: 'none' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/20240417-161442.png?v=1713341720"
            alt=""
            width={342}
            height={240}
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
        <Box display={{ xs: 'none', md: 'block', lg: 'none' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/md-3.png?v=1713248533"
            alt=""
            width={971}
            height={315}
            style={{ objectFit: 'cover' }}
          />
        </Box>
        <Box display={{ xs: 'none', lg: 'block', xl: 'none' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/xl-3.png?v=1713248539"
            alt=""
            width={958}
            height={210}
            style={{ objectFit: 'cover' }}
          />
        </Box>
        <Box display={{ xs: 'none', xl: 'block' }}>
          <Image
            src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/xl-3.png?v=1713248539"
            alt=""
            width={1204}
            height={210}
            style={{ objectFit: 'cover' }}
          />
        </Box>
      </Box>
    ),
  },
]

const CollectionList = () => {
  return (
    <Box
      sx={{
        '& img': {
          width: '100%',
          // transition: 'transform 0.3s ease-in-out',
          transition: (theme) =>
            `all 0.3s 0.1s ${theme.transitions.easing.easeInOut}`,
          verticalAlign: 'middle',
        },
      }}
    >
      <Grid
        container
        columnSpacing={{
          xs: 1,
          md: 1,
          lg: 2.5,
        }}
        rowSpacing={{
          xs: 1,
          md: 1,
          lg: 2.5,
        }}
      >
        <Grid item xs={12} lg={6}>
          <CollectionCard
            content={list[0].content}
            bg={list[0].bg}
            href={list[0].href}
            sx={{
              '&:hover': {
                '.product-image-wrapper img': {
                  transform: 'scale(1.05) translateY(-4px)',
                },
              },
            }}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Grid
            container
            rowSpacing={{ xs: 1, md: 0.5, lg: 2.5 }}
            columnSpacing={{ xs: 1, lg: 0 }}
          >
            <Grid item xs={12} md={6} lg={12}>
              <CollectionCard
                content={list[1].content}
                bg={list[1].bg}
                href={list[1].href}
                sx={{
                  '&:hover': {
                    '.product-image-wrapper img': {
                      transform: 'scale(1.05) translateY(-4px)',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <CollectionCard
                content={list[2].content}
                bg={list[2].bg}
                href={list[2].href}
                sx={{
                  '&:hover': {
                    '.product-image-wrapper img': {
                      transform: 'scale(1.08) translateY(-4px)',
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CollectionCard
            content={list[3].content}
            bg={list[3].bg}
            href={list[3].href}
            sx={{
              '&:hover': {
                '.product-image-wrapper img': {
                  transform: 'scale(1.08)',
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default CollectionList
