import { breakpointsValues } from 'constants/theme/breakpoints'
import { ImgHTMLAttributes } from 'react'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/system'

type Breakpoints = 'xs' | 'lg'

type Sizes = {
  [key in Breakpoints]?: {
    width: number
    height: number
  }
} & {
  width?: number
  height?: number
}

export type ImgPictureProps = {
  url: { [key in Breakpoints]: string } | string
  responsive?: boolean
  /**
   * 图片宽高 用于设置纵横比
   */
  originalSizes: Sizes
  alt?: string
}

// const imgResStyle = {
//   width: '100%',
//   margin: '0 auto',
//   verticalAlign: 'middle',
// }

export const ImageCustom = ({
  src,
  responsive,
  alt,
  loading = 'lazy',
  sx,
}: ImgHTMLAttributes<any> & {
  responsive: boolean
  sx?: SxProps
}) => {
  return (
    <Box
      component="img"
      src={src}
      loading={loading}
      alt={alt || ''}
      sx={{
        ...(responsive
          ? {
              // position: 'absolute',
              inset: 0,
              boxSizing: 'border-box',
              padding: 0,
              border: 'none',
              margin: 'auto',
              display: 'block',
              width: 0,
              height: 0,
              minWidth: '100%',
              maxWidth: '100%',
              minHeight: '100%',
              maxHeight: '100%',
              objectFit: 'cover',
            }
          : {
              maxWidth: '100%',
              verticalAlign: 'middle',
            }),
        ...sx,
      }}
    />
  )
}

export function ImgPicture({
  url,
  originalSizes,
  responsive = true,
  alt,
}: ImgPictureProps) {
  return (
    <Box
      component="span"
      sx={{
        boxSizing: 'border-box',
        display: 'block',
        overflow: 'hidden',
        width: 'initial',
        height: 'initial',
        background: 'none',
        opacity: 1,
        border: 0,
        margin: 0,
        padding: 0,
        position: 'relative',
        pt:
          originalSizes?.width && originalSizes?.height
            ? `${(originalSizes.height / originalSizes.width) * 100}%`
            : originalSizes.xs && originalSizes.lg
            ? {
                xs: `${
                  (originalSizes.xs.height / originalSizes.xs.width) * 100
                }%`,
                lg: `${
                  (originalSizes.lg.height / originalSizes.lg.width) * 100
                }%`,
              }
            : 0,
      }}
    >
      {typeof url === 'string' ? (
        <ImageCustom
          src={url}
          responsive={responsive}
          alt={alt}
          sx={{
            position: 'absolute',
            top: 0,
          }}
        />
      ) : (
        <picture
          style={{
            display: responsive ? 'block' : 'inline-block',
            height: '100%',
            position: 'absolute',
            width: '100%',
            top: 0,
          }}
        >
          <source
            media={`(min-width: ${breakpointsValues.lg}px)`}
            srcSet={url.lg}
          />
          <source
            media={`(min-width: ${breakpointsValues.xs})`}
            srcSet={url.xs}
          />
          <ImageCustom src={url.lg} responsive={responsive} alt={alt} />
        </picture>
      )}
    </Box>
  )
}
