import { Typography } from '@mui/material'
import { styled } from '@mui/system'

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  textAlign: 'left',
  fontWeight: 700,
  letterSpacing: '-0.3px',
  color: '#000',
  marginBottom: 12,
  [theme.breakpoints.up('md')]: {
    fontSize: 17,
    letterSpacing: '-0.34px',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 28,
    letterSpacing: '-0.56px',
    marginBottom: 24,
    textAlign: 'center',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 32,
    letterSpacing: '-0.64px',
  },
}))

export default TitleTypography
