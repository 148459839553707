import { SvgIcon, SvgIconProps } from '@mui/material'
import { LogisticsStatus } from 'types/order'

import Processing from './svg/Processing.svg'
import Shipped from './svg/Shipped.svg'
import InfoReceived from './svg/ShippingUpdate.svg'
import InTransit from './svg/InTransit.svg'
import OutForDelivery from './svg/OutForDelivery.svg'
import AvailableForPickup from './svg/AvailableForPickup.svg'
import AttemptFail from './svg/FailedAttempt.svg'
import Exception from './svg/Exception.svg'
import Delivered from './svg/Delivered.svg'

const componentTypes: { [K in LogisticsStatus]: any } = {
  Processing,
  Shipped,
  InfoReceived,
  InTransit,
  OutForDelivery,
  AvailableForPickup,
  AttemptFail,
  Exception,
  Delivered,
}

export interface LogisticsStatusIconProps extends SvgIconProps {
  status: LogisticsStatus
}
const LogisticsStatusIconIcon = ({
  status,
  sx,
  ...svgProps
}: LogisticsStatusIconProps) => (
  <SvgIcon
    component={componentTypes[status]}
    inheritViewBox
    sx={{
      '& path': {
        fill: 'currentColor!important;',
      },
      ...sx,
    }}
    {...svgProps}
  />
)

export default LogisticsStatusIconIcon
