export const shopifyUrl = `https://${process.env.NEXT_PUBLIC_SHOPIFY_PRIMARY_DOMAIN}`
// const isDev = process.env.NODE_ENV === 'development'

const routes = {
  cart: `${shopifyUrl}/cart`,
  addresses: `${shopifyUrl}/account/addresses`,
  orders: `${shopifyUrl}/account`,
}

export default routes
