/*
 * @Author: Walker Qin
 * @Description:
 * @Date: 2022-09-14 14:26:35
 * @LastEditTime: 2022-11-30 10:03:00
 */
import { ReactNode } from 'react'
import { Box } from '@mui/material'
import { ImgPicture, ImgPictureProps } from './imgPicture'
import { Block } from './container'
import { SxProps } from '@mui/system'

export interface TextOverlayImgProps {
  ImgProps: ImgPictureProps
  children?: ReactNode
  textBoxSx?: SxProps
  blockSx?: SxProps
  // 文本在底部
  textInFoot?: boolean

  /** 底部插入 */
  append?: ReactNode
}

/**
 * pc文字覆盖图片, 移动端上下排布
 */
export function TextOverlayImg({
  children,
  ImgProps,
  textBoxSx,
  blockSx,
  textInFoot,
  append,
}: TextOverlayImgProps) {
  return (
    <Block
      sx={{
        position: 'relative',
        ...blockSx,
      }}
    >
      {!textInFoot && (
        <Box
          sx={{
            position: {
              xs: 'static',
              lg: 'absolute',
            },
            textAlign: { xs: 'center', lg: 'left' },
            pl: 2,
            pr: 2,
            mb: '30px',
            zIndex: 10,
            ...textBoxSx,
          }}
        >
          {children}
        </Box>
      )}
      <ImgPicture {...ImgProps} />
      {textInFoot && (
        <Box
          sx={{
            position: {
              xs: 'static',
              lg: 'absolute',
            },
            textAlign: { xs: 'center', lg: 'left' },
            pl: 2,
            pr: 2,
            zIndex: 11,
            ...textBoxSx,
          }}
        >
          {children}
        </Box>
      )}

      {append}
    </Block>
  )
}
