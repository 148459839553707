import { Link as CollectionLink } from '@/icons'
import { Box, BoxProps } from '@mui/material'
import { ReactNode } from 'react'

export interface CollectionCardProps extends BoxProps {
  content?: ReactNode
  bg?: ReactNode
  href?: string
  contentWrapProps?: BoxProps
}

const CollectionCard = ({
  content,
  href,
  bg,
  contentWrapProps,
  ...props
}: CollectionCardProps) => {
  return (
    <Box
      className="home-collection-card"
      position="relative"
      overflow="hidden"
      borderRadius="6px"
      display="block"
      component={'a'}
      {...(href && {
        href: href ? href : 'javascript:void(0)',
        // target: '_blank',
      })}
      {...props}
    >
      <Box className="home-collection-card-bg">{bg}</Box>
      <Box
        position="absolute"
        className="home-collection-card-content"
        width="100%"
        height="100%"
        top="0"
        left="0"
        {...contentWrapProps}
      >
        {content}
      </Box>
      {href && (
        <CollectionLink
          sx={{
            position: 'absolute',
            top: {
              xs: 12,
              lg: 16,
            },
            right: {
              xs: 12,
              lg: 16,
            },
            color: 'white',
            fontSize: 20,
          }}
        />
      )}
    </Box>
  )
}

export default CollectionCard
