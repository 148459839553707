import { styled } from '@mui/system'
import { Typography, TypographyProps } from '@mui/material'

// A+ 页面块标题
export const BlockTitle = ({ sx, ...props }: TypographyProps) => {
  return (
    <Typography
      variant={'h3_700'}
      sx={{
        fontSize: { xs: '32px', lg: '40px', xl: '48px' },
        fontWeight: '700',
        lineHeight: 1.3,
        color: 'inherit',
        letterSpacing: '-0.04em',
        mb: {
          xs: '16px',
          lg: '12px',
          xl: '25px',
        },
        ...sx,
      }}
      {...props}
    />
  )
}

export const BlockSubTitle = ({ sx, ...props }: TypographyProps) => {
  return (
    <BlockTitle
      variant={'subtitle1'}
      sx={{
        fontSize: { xs: '28px', lg: '32px', xl: '36px' },
        ...sx,
      }}
      {...props}
    />
  )
}

// A+ 页面块标题
export const BlockText = ({ sx, ...props }: TypographyProps) => {
  return (
    <Typography
      sx={{
        fontSize: { xs: '16px', lg: '20px', xl: '24px' },
        fontWeight: '400',
        color: 'inherit',
        ...sx,
      }}
      {...props}
    />
  )
}

// A+ 页面次要文本
export const BlockTextMinor = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '400',
  color: '#1E2022',

  [theme.breakpoints.up('lg')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '16px',
  },
}))
