import { Box, BoxProps } from '@mui/material'
import Image from 'next/image'

export interface BannerProps extends BoxProps {
  url: string
  alt?: string
  imgs: {
    xs: string
    md: string
    lg: string
  }
  imgBlurDataURLs?: {
    xs: string
    md: string
    lg: string
  }
}

/**
 * xs: 390x520
 * md: 743x664
 * lg: height 430
 * xl: height 520
 */
export default function Banner({
  url,
  imgs,
  alt,
  imgBlurDataURLs,
  children,
  ...boxProps
}: BannerProps) {
  return (
    <Box
      className="banner"
      component="a"
      {...boxProps}
      href={
        // shopifyUrl + '/products/dreo-chefmaker-combi-fryer'
        url
        // shopifyUrl +
        // '/pages/dreo-christmas-deals?utm_source=official&utm_medium=website&utm_campaign=christmas_deals_banner'
      }
      sx={{
        display: 'block',
        background: '#0b0c1a',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          // height: 520,
          display: { xs: 'block', md: 'none' },
          img: {
            // objectFit: 'cover',
            height: 'auto',
            width: '100%',
          },
        }}
      >
        <Image
          src={imgs.xs}
          {...(imgBlurDataURLs?.xs && {
            blurDataURL: `${imgBlurDataURLs.xs}`,
            placeholder: 'blur',
          })}
          alt="mobile"
          width={780}
          height={1040}
        />
      </Box>
      <Box
        sx={{
          position: 'relative',
          // height: 640,
          display: { xs: 'none', md: 'block', lg: 'none' },
          img: {
            // objectFit: 'cover',
            height: 'auto',
            width: '100%',
          },
        }}
      >
        <Image
          src={imgs.md}
          {...(imgBlurDataURLs?.md && {
            blurDataURL: `${imgBlurDataURLs.md}`,
            placeholder: 'blur',
          })}
          alt="pad"
          width={1486}
          height={1328}
        />
      </Box>
      {/* <Box
        display={{ xs: 'none', lg: 'block', xl: 'none' }}
        height={430}
        position="relative"
      >
        <Image
          src={imgs.lg}
          {...(imgBlurDataURLs?.lg && {
            blurDataURL: `${imgBlurDataURLs.lg}`,
            placeholder: 'blur',
          })}
          alt={alt ?? 'lg'}
          fill
          style={{ objectFit: 'cover' }}
        />
      </Box> */}
      <Box
        display={{ xs: 'none', lg: 'block' }}
        // height={520}
        position="relative"
      >
        <Image
          src={imgs.lg}
          {...(imgBlurDataURLs?.lg && {
            blurDataURL: `${imgBlurDataURLs.lg}`,
            placeholder: 'blur',
          })}
          alt={alt ?? ''}
          // fill
          // style={{ objectFit: 'cover' }}
          className="w-full h-auto"
          width={3810}
          height={1040}
        />
      </Box>
      {children}
    </Box>
  )
}
