import { styled } from '@mui/system'
import { Box } from '@mui/material'

// A+ 页面块
export const Block = styled(Box)(({ theme }) => ({
  marginBottom: '80px',

  [theme.breakpoints.up('lg')]: {
    marginBottom: '90px',
  },
  [theme.breakpoints.up('xl')]: {
    marginBottom: '120px',
  },
}))
