import { ImgPicture, ImgPictureProps } from './imgPicture'
import { TypographyProps } from '@mui/material/Typography'
import { SxProps } from '@mui/system'
import { Block } from './container'
import { BlockText, BlockTitle } from './TypographyText'
import { Box } from '@mui/material'
import { ReactNode } from 'react'

export interface TitleDesImgProps {
  ImgProps?: ImgPictureProps
  title?: TypographyProps
  description?: TypographyProps
  children?: ReactNode
  boxSx?: SxProps
  textBoxSx?: SxProps
  imgChildren?: ReactNode
  footer?: ReactNode
}

// 上文下图
export function TitleDesImg({
  ImgProps,
  title,
  children,
  description,
  boxSx,
  textBoxSx,
  imgChildren,
  footer,
}: TitleDesImgProps) {
  return (
    <Block sx={{ textAlign: 'center', ...boxSx }}>
      <Box
        sx={{
          pl: 2,
          pr: 2,
          mb: {
            xs: '30px',
            lg: '48px',
            xl: '60px',
          },
          ...textBoxSx,
        }}
      >
        {title && (
          <BlockTitle
            {...title}
            sx={{
              mb: {
                xs: 2,
                lg: description ? '25px' : '0',
              },
              ...title.sx,
            }}
          />
        )}
        {description && (
          <BlockText
            {...description}
            sx={{
              ...description.sx,
            }}
          />
        )}
        {children}
      </Box>
      {ImgProps ? <ImgPicture {...ImgProps} /> : imgChildren}
      {footer}
    </Block>
  )
}
