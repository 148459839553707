import useCurrentMediaQuery from '@/hooks/useCurrentMediaQuery'
import { Link as CollectionLink, Refresh } from '@/icons'
import { ChefMaker } from '@/icons/index'
import { ImageCustom } from '@components/aPlus'
import CollectionCard from '@components/home/CollectionCard'
import { Colon, SwiperArrowLeft, SwiperArrowRight } from '@icons/index'
import {
  Box,
  Grid,
  IconButton,
  IconButtonProps,
  Stack,
  Typography,
} from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import React, { SyntheticEvent, useRef, useState } from 'react'
import { shopifyUrl } from 'src/utils/routes'
import SwiperCore, {
  Controller,
  Mousewheel,
  Navigation,
  Pagination,
} from 'swiper' // Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination' // Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { breakpointsValues } from '../../../constants/theme/breakpoints'
import TitleTypography from './TitleTypography'

const VideoMedia = React.forwardRef((props, ref) => {
  const currentMediaQuery = useCurrentMediaQuery()

  return (
    <Box
      position="relative"
      pt={{
        xs: '113.45%',
        lg: '54.06%',
      }}
    >
      <Box
        sx={{
          objectFit: 'cover',
          objectPosition: 'center',
          verticalAlign: 'middle',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        component="video"
        muted
        preload="metadata"
        playsInline
        autoPlay
        loop={currentMediaQuery != 'xs'}
        poster="https://us.dreo.com/cdn/shop/files/preview_images/4688c292f4404e2082c90b3882e298b9.thumbnail.0000000000.jpg?v=1684999591"
        onEnded={(ev: SyntheticEvent<HTMLVideoElement>) => {
          if (currentMediaQuery == 'xs') {
            ev.currentTarget.load()
            ev.currentTarget.pause()
          }
        }}
        ref={ref}
      >
        <source
          src="https://cdn.shopify.com/videos/c/vp/4688c292f4404e2082c90b3882e298b9/4688c292f4404e2082c90b3882e298b9.HD-720p-3.0Mbps-14921550.mp4"
          type="video/mp4"
        />
      </Box>
    </Box>
  )
})

const ChefMakerLink = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const refreshRef = useRef<HTMLButtonElement>(null)
  const handleRefresh = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
    if (videoRef?.current) {
      videoRef.current.currentTime = 0
      videoRef.current.play()
    }
  }
  return (
    <Box
      component={Link}
      href={shopifyUrl + '/products/dreo-chefmaker-combi-fryer'}
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
        console.log(event.target === refreshRef.current)
        if (event.target === refreshRef.current) {
          event.preventDefault()
        }
      }}
      sx={{
        borderRadius: '4.162px',
        overflow: 'hidden',
        // background: 'linear-gradient(180deg, #5B5B5B 0%, #000 100%)',
        position: 'relative',
        display: 'block',
        '& img': {
          width: '100%',
          height: { xs: 'auto', lg: '100%' },
          objectFit: 'cover',
        },
        height: { lg: '100%' },
        '.bg-img': {
          transition: (theme) =>
            `all 0.3s 0.1s ${theme.transitions.easing.easeInOut}`,
        },
        '&:hover': {
          '.bg-img': {
            transform: 'scale(1.08)',
          },
        },
      }}
    >
      <Box display={{ xs: 'block', md: 'none' }}>
        <VideoMedia ref={videoRef} />
      </Box>
      <Box
        sx={{
          display: { xs: 'none', md: 'block', lg: 'none' },
          pt: '79.53%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Image
          src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/chefmaker-pic-mobile.png?v=1700736946"
          alt="chefmaker"
          fill
          className={'bg-img'}
        />
      </Box>
      <Box display={{ xs: 'none', lg: 'block' }}>
        <Image
          src="https://cdn.shopify.com/s/files/1/0568/2472/5666/files/chefmaker-pic-pc.png?v=1700736946"
          alt="chefmaker"
          fill
          className={'bg-img'}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'space-between', lg: 'center' },
          p: {
            xs: 2.5,
            lg: '28.5px',
            xl: '42px',
          },
        }}
      >
        <Box>
          <Typography
            sx={{
              color: '#fff',
              fontSize: {
                xs: 12,
                lg: 14,
              },
              fontWeight: 600,
              mb: 1,
            }}
          >
            Combi Fryer
          </Typography>
          <ChefMaker
            sx={{
              color: '#fff',
              height: 'auto',
              fontSize: {
                xs: 139,
                lg: 156,
                xl: 200,
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: { xs: 'block', lg: 'none' },
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: 17,
                md: 15,
              },
              color: '#fff',
              fontWeight: 600,
            }}
          >
            Buy Now &gt;
          </Typography>
        </Box>
        <CollectionLink
          sx={{
            position: 'absolute',
            top: {
              xs: 12,
              lg: 16,
            },
            right: {
              xs: 12,
              lg: 16,
            },
            color: 'white',
            fontSize: 20,
            display: { xs: 'none', md: 'block' },
          }}
        />
      </Box>
      <IconButton
        onClick={handleRefresh}
        sx={{
          position: 'absolute',
          top: 20,
          right: 20,
          zIndex: 10,
          display: { md: 'none' },
          '& svg': {
            fontSize: 24,
            color: '#fff',
          },
        }}
      >
        <Refresh />
      </IconButton>
    </Box>
  )
}

const ChefMakerAccessoriesLink = () => {
  return (
    <CollectionCard
      sx={{
        borderRadius: '4.162px',
        overflow: 'hidden',
        position: 'relative',
        display: 'block',
        pt: {
          xs: '35.09%',
          md: 0,
          // lg: '41.14%',
          lg: '31.59%',
        },
        bgcolor: '#000',
        mt: { xs: 1, md: 0 },
        height: { xs: 'auto', md: '100%' },

        '.bg-img': {
          transition: (theme) =>
            `all 0.3s 0.1s ${theme.transitions.easing.easeInOut}`,
        },
        '&:hover': {
          '.bg-img': {
            transform: 'scale(1.08)',
          },
        },
      }}
      content={
        <Typography
          sx={{
            color: '#fff',
            fontSize: {
              xs: 19,
              lg: 28,
            },
            fontWeight: { xs: 600, lg: 800 },
          }}
        >
          Accessories
        </Typography>
      }
      contentWrapProps={{
        sx: {
          display: 'flex',
          alignItems: 'center',
          pl: { xs: 2.5, lg: '28px', xl: 5 },
        },
      }}
      href={
        shopifyUrl +
        '/collections/dreo-combi-fryer?filter.p.m.custom.class_name_short=Accessories'
      }
      bg={
        <>
          <picture
            className={'bg-img'}
            style={{
              display: 'block',
              height: '100%',
              position: 'absolute',
              width: '100%',
              top: 0,
            }}
          >
            <source
              media={`(min-width: ${breakpointsValues.lg})`}
              srcSet={
                'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/chefmaker_b1a9afaf-06d5-4259-9abc-18e000da379c.png?v=1700731289'
              }
            />
            <source
              media={`(min-width: ${breakpointsValues.md}px)`}
              srcSet={
                'https://cdn.shopify.com/s/files/1/0604/9461/0659/files/Accessories-md.png?v=1701151100'
              }
            />
            <source
              media={`(min-width: ${breakpointsValues.xs}px)`}
              srcSet={
                'https://cdn.shopify.com/s/files/1/0604/9461/0659/files/Accessories_xs.png?v=1701151099'
              }
            />
            <ImageCustom
              src={
                'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/chefmaker_b1a9afaf-06d5-4259-9abc-18e000da379c.png?v=1700731289'
              }
              responsive={true}
              alt={'Accessories'}
            />
          </picture>
        </>
      }
    />
  )
}

const reviews = [
  {
    content:
      "World's First Combi Fryer. Dreo's take on the kitchen segment aims to bring design,  culinary science, and technology together.",
    source: 'IF Design Reward 2023',
  },
  {
    content:
      'When I first saw ChefMaker, I was skeptical. But the proof is in the pudding, it cooked everything very nicely. There was no hassle whatsoever throughout any of the cooks.',
    source: 'Guga @SousVideEverything',
  },
  {
    content:
      "We've done dozens of videos with pork belly. I don't think in 13 years on the channel we've had it like this. I would never believe it, because it's as simple as that you don't need to mess with it. It just works.",
    source: '@Sorted Food',
  },
]

const ReviewItem = ({
  content,
  source,
}: {
  content: string
  source: string
}) => {
  return (
    <Box
      position="relative"
      px={{ xs: 3, md: 8, lg: 17, xl: 19 }}
      pt={{ xs: 3, md: 2, lg: 4.5, xl: '48px' }}
      pb={{ xs: '62px', md: '62px', lg: '52px', xl: '48px' }}
    >
      <Stack
        gap={3}
        sx={{
          textAlign: 'center',
        }}
      >
        <Box>
          <Colon
            sx={{
              fontSize: {
                xs: 48,
                md: 28,
                lg: 32.4,
                xl: 55.6,
              },
            }}
          />
        </Box>

        <Typography
          sx={{
            fontSize: { xs: 15, lg: 20 },
            fontWeight: 800,
            lineHeight: 1.5,
            color: '#1D1D1D',
          }}
        >
          {content}
        </Typography>
        <Typography
          sx={{
            fontSize: 15,
            fontWeight: 400,
            lineHeight: 1.5,
            color: '#1D1D1D',
          }}
        >
          {source}
        </Typography>
      </Stack>
    </Box>
  )
}

const ArrowButton = ({
  left,
  disabled,
  ...props
}: IconButtonProps & { left?: boolean; disabled: boolean }) => {
  return (
    <IconButton
      disabled={disabled}
      sx={{
        ...(disabled && {
          opacity: 0.2,
        }),
        '& svg': {
          fontSize: 24,
        },
      }}
      {...props}
    >
      {left ? (
        <SwiperArrowLeft sx={{ fill: 'none' }} />
      ) : (
        <SwiperArrowRight sx={{ fill: 'none' }} />
      )}
    </IconButton>
  )
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'fw-embed-feed': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & {
          channel?: string
          playlist?: string
          mode?: string
          open_in?: string
          max_videos?: string
          autoplay?: string
          captions?: string
          placement?: string
          branding?: string
          player_placement?: string
          size?: string
        },
        HTMLElement
      >
      'fw-storyblock': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & {
          channel?: string
          playlist?: string
          mode?: string
          open_in?: string
          max_videos?: string
          autoplay?: string
          captions?: string
          placement?: string
          branding?: string
          player_placement?: string
          size?: string
        },
        HTMLElement
      >
    }
  }
}

const ChefMakerReviews = () => {
  const prevRef = useRef<HTMLButtonElement>(null)
  const nextRef = useRef<HTMLButtonElement>(null)
  const [controllerSwiper] = useState<SwiperCore | undefined>(undefined)

  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <Box
      sx={{
        position: 'relative',
        mt: { xs: 1, lg: 2 },
        // px: { xs: 3, lg: 5 },
        background:
          'linear-gradient(0deg, #F7F7F7 0%, #F7F7F7 100%), linear-gradient(90deg, rgba(250, 250, 250, 0.00) 0%, #F7F7F7 51.65%, rgba(250, 250, 250, 0.00) 100%)',
        '--swiper-pagination-bullet-width': '6px',
        '--swiper-pagination-bullet-height': '6px',
        '--swiper-pagination-bullet-horizontal-gap': '4.5px',
        '--swiper-pagination-bullet-inactive-opacity': 1,
        '--swiper-pagination-color': '#1d1d1d',
        '--swiper-pagination-bullet-inactive-color': '#d4d4d4',

        '.swiper-pagination.swiper-pagination-bullets': {
          bottom: { xs: '24px' },
          top: 'auto',
          display: { xs: 'block', lg: 'none' },
        },
        '.swiper-pagination .swiper-pagination-bullet': {
          lineHeight: 0,
          // borderRadius: 0,
        },
        '.swiper-pagination .swiper-pagination-bullet-active': {
          boxShadow: '0px 0px 0px 1px var(--swiper-pagination-color)',
        },
        '.swiper-wrapper': {
          alignItems: 'center',
        },
      }}
    >
      <Box>
        <Box overflow={'hidden'}>
          <Swiper
            slidesPerView={1}
            // freeMode={true}
            modules={[Pagination, Navigation, Controller, Mousewheel]}
            className="chefmaker-review-swiper"
            pagination={{
              clickable: true,
            }}
            navigation={{
              // prevEl: prevRef.current,
              // nextEl: nextRef.current,
              prevEl: '.arrow-left',
              nextEl: '.arrow-right',
            }}
            style={{
              overflow: 'initial',
            }}
            controller={{ control: controllerSwiper }}
            onRealIndexChange={(swiper) => setActiveIndex(swiper.realIndex)}
          >
            {reviews.map((review) => (
              <SwiperSlide key={review.source}>
                <ReviewItem {...review} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        <Box
          position="absolute"
          top="50%"
          left="0"
          width="100%"
          display={{ xs: 'none', lg: 'flex' }}
          justifyContent="space-between"
          sx={{ transform: 'translateY(-50%)' }}
          px={5}
          zIndex={10}
        >
          <ArrowButton
            className="arrow-left"
            ref={prevRef}
            left
            disabled={activeIndex === 0}
          />
          <ArrowButton
            className="arrow-right"
            ref={nextRef}
            disabled={activeIndex === reviews.length - 1}
          />
        </Box>
      </Box>
    </Box>
  )
}

const CookPerfectMeals = () => {
  return (
    <Box>
      <TitleTypography
        sx={{
          display: { xs: 'block', md: 'none', lg: 'block' },
          color: {
            xs: '#7a7a7a',
            lg: '#000',
          },
        }}
      >
        Cook Perfect Meals
      </TitleTypography>
      <Grid
        container
        rowSpacing={{ xs: 1, lg: 2 }}
        columnSpacing={{ xs: 1, lg: 2 }}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <Box
            sx={{
              borderRadius: '4.162px',
              overflow: 'hidden',
            }}
          >
            <VideoMedia />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <Box
            display={{ xs: 'block', md: 'flex' }}
            height={{ xs: 'auto', md: '100%' }}
            flexDirection="column"
          >
            <Box
              sx={{
                height: { lg: '50%' },
                pb: {
                  lg: 1,
                },
              }}
            >
              <ChefMakerLink />
            </Box>
            <Box sx={{ flex: 1, pt: { md: 1 } }}>
              <ChefMakerAccessoriesLink />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box
        bgcolor="#000"
        px={{
          xs: '20px',
          lg: '80px',
        }}
        mt={{ xs: 1, lg: 2 }}
        py={{ lg: '60px', xs: '32px' }}
        sx={{ borderRadius: 1, overflow: 'hidden' }}
      >
        <script
          async={true}
          type="text/javascript"
          src="//asset.fwcdn3.com/js/embed-feed.js"
        ></script>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          :root {
            --fw-thumbnail-width: 200.8px;
            --fw-thumbnail-height: 334.6667px;
          }
        `,
          }}
        ></style>

        <fw-embed-feed
          channel="dreo"
          playlist="5E0Prv"
          mode="row"
          open_in="default"
          max_videos="0"
          autoplay="false"
          captions="false"
          placement="middle"
          branding="false"
          player_placement="bottom-left"
        />
      </Box>

      {/* reviews */}
      <ChefMakerReviews />

      {/* firework浮窗 */}
      <script async src="//asset.fwcdn3.com/js/storyblock.js"></script>

      <fw-storyblock
        channel="dreo"
        playlist="5E0Prv"
        mode="pinned"
        autoplay="true"
        player_placement="bottom-left"
      ></fw-storyblock>
    </Box>
  )
}

export default CookPerfectMeals
