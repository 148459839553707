import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Image from 'next/image'

const honors = [
  {
    alt: 'title',
    src: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/image_925.png?v=1700810932',
  },
  {
    alt: 'title',
    src: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/image_926.png?v=1700810933',
  },
  {
    alt: 'title',
    src: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/image_927.png?v=1700810933',
  },
  {
    alt: 'title',
    src: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/image_928.png?v=1700810933',
  },
  {
    alt: 'title',
    src: 'https://cdn.shopify.com/s/files/1/0568/2472/5666/files/image_939.png?v=1700810933',
  },
]

const Honors = () => {
  return (
    <Box
      className="honors"
      // pb={{ xs: 5, md: '95px', lg: '136px', xl: '140px' }}
    >
      <Typography
        mb={{ xs: 3, md: 1.5, lg: 3, xl: 4 }}
        color="#000"
        align="center"
        letterSpacing="-0.64px"
        fontWeight={700}
        fontSize={{
          xs: 20,
          lg: 28,
          xl: 32,
        }}
      >
        Media Endorsements & Awards
      </Typography>
      <Box
        component="ul"
        sx={{
          display: 'flex',
          justifyContent: {
            md: 'space-around',
          },
          flexWrap: { xs: 'wrap', md: 'nowrap' },
        }}
      >
        {honors.map((honor) => (
          <Box
            component="li"
            key={honor.src}
            className="honor"
            width={{
              xs: '50%',
            }}
            display="flex"
            justifyContent="center"
            sx={{
              mb: { xs: '42px', md: 0 },
              ':last-child': {
                mb: 0,
              },
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: {
                  xs: 128,
                  md: 94,
                  lg: 152,
                  xl: 186,
                },
                height: {
                  xs: 64,
                  md: 51,
                  lg: 72,
                  xl: 88,
                },
              }}
            >
              <Image {...honor} fill />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default Honors
