import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import Image from 'next/image'
import Link from 'next/link'

export interface CollectionFeatureCardProps {
  title: string
  href: string
  img: string
  alt?: string
}

const ImageWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '3.33px',
  overflow: 'hidden',
  position: 'relative',
  marginBottom: theme.spacing(1),
  '& img': {
    width: '100%',
    height: 'auto',
  },
}))

const Heating = styled(Typography)(({ theme }) => ({
  color: '#1D1D1D',
  fontWeight: 600,
  fontSize: 17,
  [theme.breakpoints.up('md')]: {
    fontSize: 15,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 17,
  },
}))

const CollectionFeatureCard = ({
  href,
  title,
  img,
  alt,
}: CollectionFeatureCardProps) => {
  return (
    <Box
      component={Link}
      href={href}
      sx={{
        '& img': {
          transition: (theme) =>
            `all 0.3s 0.1s ${theme.transitions.easing.easeInOut}`,
        },
        '&:hover': {
          img: {
            transform: { xs: 'none', lg: 'scale(1.1)' },
          },
        },
      }}
    >
      <ImageWrapper>
        <Image src={img} alt={`${alt}`} width={1} height={1} />
      </ImageWrapper>
      <Heating>{title}</Heating>
    </Box>
  )
}

export default CollectionFeatureCard
