import AppContainer from '@/layouts/AppContainer'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Subscription from 'src/components/home/Subscription'
import CollectionFeature from './CollectionFeature'
import CollectionList from './CollectionList'
import CookPerfectMeals from './CookPerfectMeals'
import Honors from './Honors'
import Slideshow from './Slideshow'

export default function Home() {
  return (
    <Box className="home">
      <Slideshow />
      {/* <Banner /> */}
      <AppContainer
        maxWidth="xl"
        sx={{
          py: { xs: 6, md: 8, lg: '100px', xl: '120px' },
          overflow: 'hidden',
        }}
      >
        <Stack spacing={{ xs: 5, md: 6, lg: '100px', xl: 15 }}>
          <CollectionList />
          <CollectionFeature />
          <CookPerfectMeals />
          <Honors />
        </Stack>
      </AppContainer>
      <Subscription />
    </Box>
  )
}
